import React from "react";
import ResourceLinks from "../ResourceLinks";

const DigitalLearningDevelopment = (props) => {

    return(
        <ResourceLinks
            // what the user scored in this section
            score={props.score}

            // these resource links will be managed and selected accordingly
            resourceData={[
                {
                    link: 'https://www.linkedin.com/learning-login/share?account=75842122&forceAccount=false&redirect=https%3A%2F%2Fwww.linkedin.com%2Flearning%2Flearning-to-teach-online-2%3Ftrk%3Dshare_ent_url%26shareId%3DscAT2jIzQqSozZjGhuFaUw%253D%253D',
                    label: 'Learning to Teach Online',
                    weighting: ['Beginner']
                },
                {
                    link: 'https://www.linkedin.com/learning-login/share?account=75842122&forceAccount=false&redirect=https%3A%2F%2Fwww.linkedin.com%2Flearning%2Felearning-essentials-instructional-design%3Ftrk%3Dshare_ent_url%26shareId%3DJv%252FG%252BD99SKiPekBL%252BO9sSw%253D%253D',
                    label: 'Elearning Essentials: Instructional Design',
                    weighting: ['Beginner']
                },
                {
                    link: 'https://www.linkedin.com/learning-login/share?account=75842122&forceAccount=false&redirect=https%3A%2F%2Fwww.linkedin.com%2Flearning%2Flearning-with-your-mobile-device%3Ftrk%3Dshare_ent_url%26shareId%3DFVRJZ8vjSvGhct6NF9LFVA%253D%253D',
                    label: 'Learning with your Mobile Device',
                    weighting: ['Beginner']
                },
                {
                    link: 'https://www.linkedin.com/learning/onenote-2016-essential-training/add-text-and-quick-notes?u=75842122',
                    label: 'OneNote 2016 Essential Training',
                    weighting: ['Beginner', 'Intermediate']
                },
                {
                    link: 'https://www.linkedin.com/learning/infographics-planning-and-wireframing/using-the-exercise-files?u=75842122',
                    label: 'Infographics: Planning and Wireframing',
                    weighting: ['Intermediate']
                },
                {
                    link: 'https://www.linkedin.com/learning-login/share?account=75842122&forceAccount=false&redirect=https%3A%2F%2Fwww.linkedin.com%2Flearning%2Fmarketing-copywriting-for-social-media%2Fspelling-and-grammar-tools%3Ftrk%3Dshare_video_url%26shareId%3D01Awl4AMRbehNRkFG6ydkw%253D%253D',
                    label: 'Spelling and grammar tools (video)',
                    weighting: ['Intermediate']
                },
                {
                    link: 'https://www.linkedin.com/learning-login/share?account=75842122&forceAccount=false&redirect=https%3A%2F%2Fwww.linkedin.com%2Flearning%2Fdesigning-learning-experiences-in-the-metaverse%3Ftrk%3Dshare_ent_url%26shareId%3DHI4YMyw2SXiUpuqnIbg1Kg%253D%253D',
                    label: 'Designing Learning Experiences in the Metaverse',
                    weighting: ['Intermediate']
                },
                {
                    link: 'https://www.linkedin.com/learning/security-tips/use-a-password-manager?u=75842122',
                    label: 'Security Tips',
                    weighting: ['General']
                },
                {
                    link: 'https://www.linkedin.com/learning/learning-pinterest-2/use-pinterest-to-collect-and-manage-your-inspirations?u=75842122',
                    label: 'Learning Pinterest',
                    weighting: ['Beginner']
                },
                {
                    link: 'https://www.linkedin.com/learning/google-calendar-essential-training/be-a-schedule-champion-with-google-calendar?u=75842122',
                    label: 'Google Calendar Essential Training',
                    weighting: ['Beginner', 'Intermediate']
                },
                {
                    link: 'https://www.linkedin.com/learning/learning-lucidchart/visualize-complex-ideas-with-lucidchart?u=75842122',
                    label: 'Learning Lucidchart',
                    weighting: ['Beginner']
                }
            ]}
        />
    )
}

export default DigitalLearningDevelopment;