import React from "react";
import ResourceLinks from "../ResourceLinks";

const DigitalIdentityAndWellbeing = (props) => {

    return(
        <ResourceLinks
            // what the user scored in this section
            score={props.score}

            // these resource links will be managed and selected accordingly
            resourceData={[
                {
                    link: 'https://www.linkedin.com/learning/twitter-essential-training-5/be-part-of-the-global-conversation-on-twitter?u=75842122',
                    label: 'Twitter Essential Training',
                    weighting: ['Beginner', 'Intermediate']
                },
                {
                    link: 'https://www.linkedin.com/learning/learning-iphone-and-ipad-security-2/welcome?u=75842122',
                    label: 'Learning iPhone and iPad Security',
                    weighting: ['Beginner']
                },
                {
                    link: 'https://www.linkedin.com/learning-login/share?account=75842122&forceAccount=false&redirect=https%3A%2F%2Fwww.linkedin.com%2Flearning%2Fworking-with-computers-and-devices%2Fthe-security-settings-on-your-computer%3Ftrk%3Dshare_video_url%26shareId%3DG7FTKOHQSC%252BkNOfO98E7bg%253D%253D',
                    label: 'The security settings on your computer (video)',
                    weighting: ['Beginner']
                },
                {
                    link: 'https://www.linkedin.com/learning-login/share?account=75842122&forceAccount=false&redirect=https%3A%2F%2Fwww.linkedin.com%2Flearning%2Fworking-and-collaborating-online%2Fcreate-strong-passwords%3Ftrk%3Dshare_video_url%26shareId%3DFQNnO4wPS2%252BNipSfa4ZVVg%253D%253D',
                    label: 'Create strong passwords (video)',
                    weighting: ['Beginner']
                },
                {
                    link: 'https://www.linkedin.com/learning-login/share?account=75842122&forceAccount=false&redirect=https%3A%2F%2Fwww.linkedin.com%2Flearning%2Fworking-and-collaborating-online%2Fprotect-yourself-from-phishing-and-online-scams%3Ftrk%3Dshare_video_url%26shareId%3DYyS9Z2wHRw6lIL7M8%252BlY4A%253D%253D',
                    label: 'Protect yourself from phishing and online scams (video)',
                    weighting: ['Beginner']
                },
                {
                    link: 'https://www.linkedin.com/learning-login/share?account=75842122&forceAccount=false&redirect=https%3A%2F%2Fwww.linkedin.com%2Flearning%2Fworking-and-collaborating-online%2Fsafely-shop-online%3Ftrk%3Dshare_video_url%26shareId%3DZtw%252Ffu%252BQTdaMDw1sAN%252B18A%253D%253D',
                    label: 'Safely shop online (video)',
                    weighting: ['Beginner']
                },
                {
                    link: 'https://www.linkedin.com/learning-login/share?account=75842122&forceAccount=false&redirect=https%3A%2F%2Fwww.linkedin.com%2Flearning%2Fsupporting-your-kids-learning-at-home%2Fhelping-students-be-safe-online%3Ftrk%3Dshare_video_url%26shareId%3DYthk4d4SQ%252BulNmjojPzYcA%253D%253D',
                    label: 'helping students be safe online (video)',
                    weighting: ['Beginner']
                },
                {
                    link: 'https://www.linkedin.com/learning-login/share?account=75842122&forceAccount=false&redirect=https%3A%2F%2Fwww.linkedin.com%2Flearning%2Fprepare-for-returning-to-the-workplace%2Freset-boundaries%3Ftrk%3Dshare_video_url%26shareId%3D68wtgNYRRwuUpRu5TCh8QQ%253D%253D',
                    label: 'Reset boundaries (video)',
                    weighting: ['Beginner']
                },
                {
                    link: 'https://www.linkedin.com/learning-login/share?account=75842122&forceAccount=false&redirect=https%3A%2F%2Fwww.linkedin.com%2Flearning%2Fworking-and-collaborating-online%2Fmanage-your-digital-footprint%3Ftrk%3Dshare_video_url%26shareId%3DDHKEgG1rQFC0WgXL4cirog%253D%253D',
                    label: 'Manage your digital footprint (video)',
                    weighting: ['Beginner']
                },
                {
                    link: 'https://www.linkedin.com/learning-login/share?account=75842122&forceAccount=false&redirect=https%3A%2F%2Fwww.linkedin.com%2Flearning%2Flearn-to-control-your-attention%3Ftrk%3Dshare_ent_url%26shareId%3D0Rl5Zr80RL6xf%252FKcBh59Og%253D%253D',
                    label: 'Learn to Control Your Attention (audio)',
                    weighting: ['Beginner']
                },
                {
                    link: 'https://www.linkedin.com/learning-login/share?account=75842122&forceAccount=false&redirect=https%3A%2F%2Fwww.linkedin.com%2Flearning%2Ftaking-a-break-from-your-phone%3Ftrk%3Dshare_ent_url%26shareId%3DNI8mwB%252BiSy6GqBBbVIjjRA%253D%253D',
                    label: 'Taking a Break from your Phone',
                    weighting: ['Beginner']
                },
                {
                    link: 'https://www.linkedin.com/learning/learning-computer-security-and-internet-safety-3/verify-updates-happen-automatically?u=75842122',
                    label: 'Learning Computer Security and Internet Safety',
                    weighting: ['General']
                },
                {
                    link: 'https://www.linkedin.com/learning-login/share?account=75842122&forceAccount=false&redirect=https%3A%2F%2Fwww.linkedin.com%2Flearning%2Fcybersecurity-at-work%3Ftrk%3Dshare_ent_url%26shareId%3DPXoPO3NBSIyDTXNh%252BYgvZg%253D%253D',
                    label: 'Cybersecurity at Work',
                    weighting: ['General']
                },
                {
                    link: 'https://www.linkedin.com/learning-login/share?account=75842122&forceAccount=false&redirect=https%3A%2F%2Fwww.linkedin.com%2Flearning%2Fsupporting-your-mental-health-while-working-from-home%3Ftrk%3Dshare_ent_url%26shareId%3DyUfKeREuQsCc7wuwb6RQzQ%253D%253D',
                    label: 'Supporting your Mental Health While Working From Home',
                    weighting: ['General']
                },
                {
                    link: 'https://www.linkedin.com/learning/learning-android-security/welcome?u=75842122',
                    label: 'Learning Android Security',
                    weighting: ['Beginner']
                },
                {
                    link: 'https://www.linkedin.com/learning/learning-linkedin-2021/get-started-with-linkedin?u=75842122',
                    label: 'Learning LinkedIn',
                    weighting: ['Beginner']
                },
                {
                    link: 'https://www.linkedin.com/learning/it-security-foundations-core-concepts-2/tech-savvy-tech-safe?u=75842122',
                    label: 'IT Security Foundations: Core Concepts',
                    weighting: ['Beginner']
                },
                {
                    link: 'https://www.linkedin.com/learning/learning-vpn/what-is-a-vpn?u=75842122',
                    label: 'Learning VPN',
                    weighting: ['Beginner']
                },
                {
                    link: 'https://www.linkedin.com/learning/windows-10-security/welcome?u=75842122',
                    label: 'Windows 10: Security',
                    weighting: ['Intermediate']
                },
                {
                    link: 'https://www.linkedin.com/learning/learning-mobile-device-security-3/securing-mobile-devices?u=75842122',
                    label: 'Learning Mobile Device Security',
                    weighting: ['Beginner']
                },
                {
                    link: 'https://www.linkedin.com/learning/topics/security-3?u=75842122',
                    label: 'Cybersecurity Foundations',
                    weighting: ['Intermediate']
                },
                {
                    link: 'https://www.linkedin.com/learning/cybersecurity-awareness-malware-explained/welcome?u=75842122',
                    label: 'Cybersecurity Awareness: Malware Explained',
                    weighting: ['Beginner']
                },
                {
                    link: 'https://www.linkedin.com/learning/securing-your-home-office/securing-your-home-office?u=75842122',
                    label: 'Securing Your Home Office',
                    weighting: ['Beginner']
                }
            ]}
        />
    )
}

export default DigitalIdentityAndWellbeing;